import Api from '@/services/Index';

const getAttendanceForSection = async (sectionId, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/attendances/' + sectionId + '/weeks', formData);
}

const getSectionStudents = async (sectionId, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/sections/' + sectionId + '/students', formData);
}

const getAttendanceWithSectionAndWeek = async (sectionId, weekNumber, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/attendances/' + sectionId + '/' + weekNumber, formData);
}

const save = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/attendances/', formData);
}

const getAttendanceDays = async (sectionId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/daily-attendances/' + sectionId + '/day');
}

const getAttendanceDayStudents = async (sectionId, day) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/daily-attendances/' + sectionId + '/'+day);
}

const saveDaily = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/daily-attendances', formData);
}

const entryTrackingReport = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/daily-attendances/entry-tracking-report', formData);
}

const dailyAttendanceReport = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/daily-attendances/report', formData);
}

export default {
    getAttendanceForSection,
    getSectionStudents,
    getAttendanceWithSectionAndWeek,
    save,
    getAttendanceDays,
    getAttendanceDayStudents,
    saveDaily,
    entryTrackingReport,
    dailyAttendanceReport
}
